$(document).ready(function(){

  // background resize
  /*
  function resizeBG () {
    var element = $('.bgimg img');
    var bgWidth = element[0].width;
    var bgHeight = element[0].height;
    console.log(bgWidth);
    var ww = $(window).innerWidth;
    var wh = $(window).innerHeight-14;
    var ml = 0;
    var mt = 0;
    var newWidth, newHeight, ratioW, ratioH;

    ratioW = ww/bgWidth;
    newHeight = bgHeight*ratioW;

    if(newHeight > wh){
      mt = 0 - (newHeight - wh)/2;
      //mt = (0 - (newHeight - wh));
      newWidth = bgWidth*ratioW;
    }else{
      ratioH = wh/bgHeight;
      newHeight = bgHeight*ratioH;
      newWidth = bgWidth*ratioH;
      ml = 0 - (newWidth - ww)/2;
    }

    element.css("width", newWidth + 'px');
    element.css("height", newHeight + 'px');
    element.css({'margin-left':ml+'px'});
    element.css({'margin-top':mt+'px'});
    //console.log(mt);
    bgWidth = newWidth;
    bgHeight = newHeight;

  };

  $( window ).resize(function() {
    resizeBG();
  });
*/
  // end background resize


// scroll stuff

  if($('body').hasClass('home') === true){

    // load random background image with loader
    img = new Image();
    bgImg = $('.bgimg');
    bgImgSrc = bgImg.attr('data-bgimg');
    img.onload = function(){
      bgImg[0].style.backgroundImage = "url("+bgImgSrc+")";
      bgImg.addClass('loaded');
    }
    img.src = bgImgSrc;

    var maxHeight = window.innerHeight - 65;

    $( window ).resize(function() {
      maxHeight = window.innerHeight - 65;
    });

    var scrolled = 0;

    function handleScroll () {
      scrolled = window.scrollY;

      if(scrolled > maxHeight){
        document.querySelector('body').classList.add('fixed');
      }else{
        document.querySelector('body').classList.remove('fixed');
      }
      if(scrolled > 65){
        document.querySelector('body').classList.add('scrolling');
      }else{
        document.querySelector('body').classList.remove('scrolling');
      }
    }

    window.addEventListener('scroll', handleScroll);

  }


  // end scroll stuff

  // menu
  $('.m-menu').click(function(){
    if ($('body').hasClass('m-menu-open')){
      $('body').removeClass('m-menu-open');
    }else{
      $('body').addClass('m-menu-open');
    }
  });

  // end menu

  // sub menu

  $('.subnav button').click(function(){ // not used
    if ($('body').hasClass('sub-menu-open')){
      $('body').removeClass('sub-menu-open');
    }else{
      $('body').addClass('sub-menu-open');
    }

  });

  // end sub menu

  // sub menu
  $('.subnav .active').click(function(e){

    if($(window).width() <=  480){
      e.preventDefault();
      var parent = $(this).parent().parent();
      if(parent.hasClass('subnavopen')){
        parent.removeClass('subnavopen');
      }else{
        parent.addClass('subnavopen');
      }
    }
  });



});
